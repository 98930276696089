define("legendary-file-upload/utils/attachment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    fileUpload: null,
    content: null,
    task: null,
    model: Ember.computed.readOnly('content.model'),
    size: Ember.computed.readOnly('content.size'),
    uploaded: Ember.computed.readOnly('content.uploaded'),
    fileName: Ember.computed.readOnly('content.fileObject.name'),
    percentUploaded: Ember.computed.readOnly('content.percentComplete'),
    fileData: Ember.computed.alias('content.fileData'),
    upload: function upload() {
      var fileUploadService = this.get('fileUpload');
      var content = this.get('content');
      var task = fileUploadService.startUpload(content);
      this.set('task', task);
      return task;
    },
    cancel: function cancel() {
      var task = this.get('task');

      if (task) {
        task.cancel();
      }
    }
  });

  _exports.default = _default;
});