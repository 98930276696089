define("legendary-file-upload/models/file-upload", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    filename: _emberData.default.attr('string'),
    contentType: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    uploadUrl: _emberData.default.attr('string'),
    status: _emberData.default.attr('string')
  });

  _exports.default = _default;
});