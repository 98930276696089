define("legendary-file-upload/components/link-to-file-upload", ["exports", "legendary-file-upload/templates/components/link-to-file-upload"], function (_exports, _linkToFileUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LinkToFileUploadComponent = Ember.Component.extend({
    model: null,
    fileUpload: Ember.inject.service(),
    layout: _linkToFileUpload.default,
    tagName: 'a',
    href: '',
    attributeBindings: ['href'],
    click: function click(e) {
      e.preventDefault();
      e.stopPropagation();
      var model = this.get('model');

      if (model) {
        this.get('fileUpload').download(model);
      }
    }
  });
  LinkToFileUploadComponent.reopenClass({
    positionalParams: ['model']
  });
  var _default = LinkToFileUploadComponent;
  _exports.default = _default;
});