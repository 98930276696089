define("legendary-file-upload/components/file-upload-input", ["exports", "legendary-file-upload/templates/components/file-upload-input", "legendary-file-upload/styles/components/file-upload-input"], function (_exports, _fileUploadInput, _fileUploadInput2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    upload: null,
    onChange: function onChange() {},
    onCancel: function onCancel() {},
    placeholder: '(select a file)',
    layout: _fileUploadInput.default,
    styles: _fileUploadInput2.default,
    fileUpload: Ember.inject.service(),
    hasUpload: Ember.computed.bool('upload'),
    _getInputElement: function _getInputElement() {
      var element = this.get('element');
      return element.querySelector('input[type="file"]');
    },
    actions: {
      selectFile: function selectFile() {
        this._getInputElement().click();
      },
      fileSelected: function fileSelected() {
        var input = this._getInputElement();

        var file = input.files[0];
        var upload = this.get('fileUpload').createUpload(file);
        this.onChange(upload);
        input.value = '';
      },
      clear: function clear() {
        this.onChange(null);
      },
      inputClicked: function inputClicked() {
        if (!this.get('hasUpload')) {
          this.send('selectFile');
        }
      }
    }
  });

  _exports.default = _default;
});