define("legendary-file-upload/mixins/adapters/file-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    getDownloadUrl: function getDownloadUrl(fileUpload) {
      var id = fileUpload.get('id');
      var url = "".concat(this.get('host'), "/").concat(this.get('namespace'), "/file_downloads/").concat(id);
      return this.ajax(url, 'GET').then(function (result) {
        return result.data.attributes.url;
      });
    }
  });

  _exports.default = _default;
});