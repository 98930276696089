define("ember-css-modules/mixins/component-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Mixin.create({
    localClassNames: null,
    localClassNameBindings: null,
    concatenatedProperties: ['localClassNames', 'localClassNameBindings'],
    init: function init() {
      this._super();

      if (this.tagName === '') return;
      this.classNameBindings = [].concat(_toConsumableArray(this.classNameBindings), _toConsumableArray(localClassNames(this)), [LOCAL_CLASS_NAMES_CP]);

      if (this.localClassNameBindings.length) {
        var value = localClassNamesCP(this.localClassNameBindings, this.get('__styles__'));
        Ember.defineProperty(this, LOCAL_CLASS_NAMES_CP, value);
      }
    },
    __styles__: Ember.computed(function () {
      var key = this._debugContainerKey;

      if (!key) {
        return;
      }

      var name = key.replace(/^component:/, '');
      var layout = this.layout || Ember.getOwner(this).lookup("template:components/".concat(name));
      (false && !(layout) && Ember.assert("Unable to resolve localClassNames or localClassNameBindings for component ".concat(name, ", which has no ") + "layout. You can fix this by either creating an empty template for your component or importing and " + "using the styles hash directly instead, e.g. `classNames: styles['my-class']`.", layout));
      (false && !(false) && Ember.deprecate('Support for `localClassNames`, `localClassNameBindings` and the `@localClassName` and `@localClassNames` ' + 'decorators will be removed in the next major release of ember-css-modules. The `' + name + '` component ' + 'uses one or more of these APIs. See the ECM 1.5.0 release notes for further details and migration options: ' + 'https://github.com/salsify/ember-css-modules/releases/tag/v1.5.0', false, {
        id: 'ember-css-modules.classic-component-apis',
        for: 'ember-css-modules',
        until: '2.0.0',
        since: {
          enabled: '1.5.0'
        }
      })); // Since https://github.com/emberjs/ember.js/pull/18096

      if (typeof layout === 'function') layout = layout(Ember.getOwner(this)); // This is not public API and might break at any time...

      var moduleName = (layout.meta || layout.referrer).moduleName.replace(/\.hbs$/, '');

      if (/\/template$/.test(moduleName)) {
        return tryLoad(moduleName.replace(/template$/, 'styles'));
      } else if (/\/templates\//.test(moduleName)) {
        return tryLoad(moduleName.replace('/templates/', '/styles/'));
      }

      return;
    })
  });

  _exports.default = _default;

  function tryLoad(path) {
    if (require.has(path)) {
      return require(path).default;
    }
  }

  var LOCAL_CLASS_NAMES_CP = '__ecm_local_class_names__';

  function localClassNames(component) {
    return component.localClassNames.map(function (className) {
      return "__styles__.".concat(className);
    });
  }

  function localClassNamesCP(localClassNameBindings, styles) {
    var bindings = localClassNameBindings.map(function (binding) {
      var _binding$split = binding.split(':'),
          _binding$split2 = _slicedToArray(_binding$split, 3),
          property = _binding$split2[0],
          trueStyle = _binding$split2[1],
          falseStyle = _binding$split2[2];

      var trueClasses = styles[trueStyle || Ember.String.dasherize(property)] || '';
      var falseClasses = styles[falseStyle] || '';
      var isBoolean = !!trueStyle;
      return {
        property: property,
        trueClasses: trueClasses,
        falseClasses: falseClasses,
        isBoolean: isBoolean
      };
    });
    return Ember.computed.apply(void 0, _toConsumableArray(bindings.map(function (binding) {
      return binding.property;
    })).concat([function () {
      var _this = this;

      var classes = [];
      bindings.forEach(function (binding) {
        var value = _this.get(binding.property);

        if (binding.isBoolean || typeof value !== 'string') {
          classes.push(value ? binding.trueClasses : binding.falseClasses);
        } else {
          classes.push(value.split(/\s+/).map(function (key) {
            return styles[key];
          }).join(' '));
        }
      });
      return classes.join(' ');
    }]));
  }
});