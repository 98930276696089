define("legendary-file-upload/utils/attachments-list", ["exports", "legendary-file-upload/utils/attachment"], function (_exports, _attachment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.ArrayProxy.extend({
    fileUpload: null,
    content: null,
    init: function init() {
      this.set('content', Ember.A());

      this._super();
    },
    addFile: function addFile(file) {
      var fileUploadService = this.get('fileUpload');
      var upload = fileUploadService.createUpload(file);

      var attachment = _attachment.default.create({
        fileUpload: fileUploadService,
        content: upload
      });

      this.pushObject(attachment);
      return attachment;
    },
    addFiles: function addFiles(files) {
      for (var i = 0; i < files.length; i++) {
        this.addFile(files[i]);
      }
    },
    upload: function upload() {
      var attachments = this.get('content');
      attachments.forEach(function (attachment) {
        attachment.upload();
      });
      return Ember.RSVP.all(attachments.mapBy('task'));
    },
    cancel: function cancel() {
      var attachments = this.get('content');
      attachments.forEach(function (attachment) {
        attachment.cancel();
      });
    },
    totalSize: Ember.computed('content.@each.size', {
      get: function get() {
        return this.get('content').mapBy('size').reduce(function (prev, curr) {
          return prev + curr;
        });
      }
    }),
    uploadedSize: Ember.computed('content.@each.uploaded', {
      get: function get() {
        return this.get('content').mapBy('uploaded').reduce(function (prev, curr) {
          return prev + curr;
        });
      }
    }),
    percentUploaded: Ember.computed('totalSize', 'uploadedSize', {
      get: function get() {
        var p = this.getProperties('totalSize', 'uploadedSize');

        if (p.totalSize) {
          return Math.round(p.uploadedSize / p.totalSize * 100);
        } else {
          return 0;
        }
      }
    })
  });

  _exports.default = _default;
});