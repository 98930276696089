define("ember-animated/element-remove", [], function () {
  "use strict";

  // Polyfill Element.remove on IE11
  // from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
  var classPrototypes = [window.Element, window.CharacterData, window.DocumentType].filter(function (klass) {
    return klass;
  }).map(function (klass) {
    return klass.prototype;
  });

  (function (arr) {
    arr.forEach(function (item) {
      if (Object.prototype.hasOwnProperty.call(item, 'remove')) {
        return;
      }

      Object.defineProperty(item, 'remove', {
        configurable: true,
        enumerable: true,
        writable: true,
        value: function remove() {
          this.parentNode.removeChild(this);
        }
      });
    });
  })(classPrototypes);
});