define("legendary-file-upload/templates/components/file-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DWvkNi9R",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[15,0,[31,[\"form-control \",[30,[36,2],[\"input\"],[[\"from\"],[\"legendary-file-upload/styles/components/file-input\"]]]]]],[12],[2,\"\\n\"],[6,[37,3],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[35,0,[\"name\"]]],[2,\"\\n\\n        \"],[11,\"a\"],[24,6,\"\"],[24,0,\"ml-2\"],[4,[38,1],[[32,0],\"clear\"],null],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"fa fa-times-circle text-danger\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"text-muted\"],[12],[2,\"(select a file)\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"span\"],[14,0,\"input-group-btn\"],[12],[2,\"\\n    \"],[11,\"a\"],[24,6,\"\"],[24,0,\"btn btn-primary\"],[16,\"disabled\",[34,4]],[4,[38,1],[[32,0],\"selectFile\"],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-folder-o\"],[12],[13],[2,\" Select file...\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[11,\"input\"],[16,0,[31,[[30,[36,2],[\"input-file\"],[[\"from\"],[\"legendary-file-upload/styles/components/file-input\"]]]]]],[24,4,\"file\"],[4,[38,1],[[32,0],\"fileSelected\"],[[\"on\"],[\"change\"]]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"action\",\"local-class\",\"if\",\"hasUpload\"]}",
    "meta": {
      "moduleName": "legendary-file-upload/templates/components/file-input.hbs"
    }
  });

  _exports.default = _default;
});