define("ember-animated/templates/components/animated-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lpA1vi7S",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"items\"]]],[[\"key\",\"rules\",\"use\",\"duration\",\"group\",\"watch\",\"initialInsertion\",\"finalRemoval\"],[[32,0,[\"key\"]],[32,0,[\"rules\"]],[32,0,[\"use\"]],[32,0,[\"duration\"]],[32,0,[\"group\"]],[32,0,[\"watch\"]],[32,0,[\"initialInsertion\"]],[32,0,[\"finalRemoval\"]]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"animated-each\"]}",
    "meta": {
      "moduleName": "ember-animated/templates/components/animated-value.hbs"
    }
  });

  _exports.default = _default;
});