define("ember-animated/-private/bounds", ["exports", "ember-animated/domrect-polyfill"], function (_exports, _domrectPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.shiftedBounds = shiftedBounds;
  _exports.resizedBounds = resizedBounds;
  _exports.relativeBounds = relativeBounds;
  _exports.emptyBounds = void 0;

  /**
    Shifts the coordinates of the given bounds using the provided
    x and y axis offset.
  
    @private
    @function shiftedBounds
    @hide
    @param {Object} bounds The original bounds.
    @param {number} dx X axis offset.
    @param {number} dy Y axis offset.
    @return {Object} The newly calculated bounds.
  */
  function shiftedBounds(bounds, dx, dy) {
    return new DOMRect(bounds.x + dx, bounds.y + dy, bounds.width, bounds.height);
  }
  /**
    Resizes the coordinates of the given bounds using the provided
    width and height.
  
    @private
    @function resizedBounds
    @hide
    @param {Object} bounds The original bounds.
    @param {number} width The target width.
    @param {number} height The target height.
    @return {Object} The newly calculated bounds.
  */


  function resizedBounds(bounds, width, height) {
    return new DOMRect(bounds.x, bounds.y, width, height);
  }
  /**
    Shifts the coordinates of the given bounds so they are relative to
    the offset.
  
    @private
    @function relativeBounds
    @hide
    @param {Object} bounds The original bounds.
    @param {Object} offset The offset.
    @return {Object} The newly calculated bounds.
  */


  function relativeBounds(bounds, offset) {
    return shiftedBounds(bounds, -offset.left, -offset.top);
  }
  /**
    An empty bounds object.
  
    @private
    @hide
    @constant {Object} emptyBounds
  */


  var emptyBounds = Object.freeze(new DOMRect(0, 0, 0, 0));
  _exports.emptyBounds = emptyBounds;
});