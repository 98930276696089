define("legendary-file-upload/styles/components/file-upload-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "input": "_input_1v2417",
    "input-file": "_input-file_1v2417"
  };
  _exports.default = _default;
});