define("legendary-file-upload/components/file-input", ["exports", "legendary-file-upload/templates/components/file-input", "legendary-file-upload/styles/components/file-input"], function (_exports, _fileInput, _fileInput2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    value: null,
    onChange: function onChange() {},
    layout: _fileInput.default,
    styles: _fileInput2.default,
    _getInputElement: function _getInputElement() {
      var element = this.get('element');
      var style = '.' + _fileInput2.default['input-file'];
      return element.querySelector(style);
    },
    actions: {
      selectFile: function selectFile() {
        this._getInputElement().click();
      },
      fileSelected: function fileSelected() {
        var input = this._getInputElement();

        var file = input.files[0];
        this.onChange(file);
        input.value = '';
      },
      clear: function clear() {
        this.onChange(null);
      }
    }
  });

  _exports.default = _default;
});